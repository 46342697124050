<template>
    <div>
        <div class="trustscore-progress">
            <div class="trustscore-progress__dot" ref="dot"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        score: Number,
    },

    mounted() {
        this.placeDot();
    },

    methods: {
        placeDot() {
            const progressWidth = 85;
            const percent = (this.score / 100) * 100;
            const progressOffset = ((progressWidth / 100) * percent).toFixed(0);
            if (percent > 97) {
                this.$refs.dot.style.left = progressOffset - 10 + 'px';
            } else if (percent < 5) {
                this.$refs.dot.style.left = progressOffset + 5 + 'px';
            } else {
                this.$refs.dot.style.left = progressOffset - 4 + 'px';
            }
        },
    },
};
</script>

<style lang="scss">
.trustscore-progress {
    position: relative;
    display: inline-block;
    content: "";
    margin-right: 8px;
    width: 85px;
    height: 4px;
    border-radius: 4px;
    background: linear-gradient(to right, #E15162 0%, #F8E164 50%, #73CE84 100%);
    transform: translateY(-2px);
    z-index: 1;

    &__dot {
        position: absolute;
        left: 0;
        top: -4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: var(--body-text-color);
        border: 2px solid var(--card-background);
        z-index: 2;
        content: "";
        box-shadow: 0 0 4px 0 var(--header-box-shadow-color);
    }
}
</style>
