<template>
    <div v-bind:class="isMobile ? 'collection-social-links--mobile' : 'collection-social-links'">
        <a v-for="link in list"
            v-bind:href="link"
            v-bind:class="isMobile ? 'collection-social-links--mobile__link' : 'collection-social-links__link'"
            target="_blank"
            v-bind:key="link"
        >
            <component v-bind:is="getIcon(link)" />
        </a>
    </div>
</template>

<script>
import TelegramLogo from '@img/icons/social/telegram.svg?inline';
import DiscordLogo from '@img/icons/social/discord.svg?inline';
import FacebookLogo from '@img/icons/social/facebook.svg?inline';
import InstagramLogo from '@img/icons/social/instagram.svg?inline';
import RedditLogo from '@img/icons/social/reddit.svg?inline';
import YoutubeLogo from '@img/icons/social/youtube.svg?inline';
import VKLogo from '@img/icons/social/vk.svg?inline';
import GithubLogo from '@img/icons/social/github.svg?inline';
import TikTokLogo from '@img/icons/social/tiktok.svg?inline';
import TwitterLogo from '@img/icons/social/x-com.svg?inline';
import GlobeDefault from '@img/icons/tonscan/domain_renew.svg?inline';

const linksMap = Object.freeze({
    't.me': TelegramLogo,
    'telegram.org': TelegramLogo,
    'discord.com': DiscordLogo,
    'discord.gg': DiscordLogo,
    'facebook.com': FacebookLogo,
    'instagram.com': InstagramLogo,
    'reddit.com': RedditLogo,
    'youtube.com': YoutubeLogo,
    'youtu.be': YoutubeLogo,
    'vk.com': VKLogo,
    'tiktok.com': TikTokLogo,
    'github.com': GithubLogo,
    'twitter.com': TwitterLogo,
    'x.com': TwitterLogo,
});

export default {
    props: {
        socialLinks: Array,
    },
    computed: {
        list() {
            return this.socialLinks.slice(0, 4);
        },
    },
    methods: {
        getIcon(link) {
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, value] of Object.entries(linksMap)) {
                if (link.includes(key)) return value;
            }
            return GlobeDefault;
        },
    },
};
</script>

<style scoped lang="scss">
.collection-social-links {
    display: flex;
    position: absolute;
    right: 12px;
    top: 6px;
    z-index: 5;
    gap: 10px;

    &__link {
        display: block;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        cursor: pointer;
        color: white;
        fill: white;
        background-color: var(--body-light-muted-color);
        backdrop-filter: blur(5px);

        svg {
            width: 20px;
            height: 20px;
            margin-top: 4px;
            margin-left: 4px;
        }
    }
}

.collection-social-links--mobile {
    display: flex;
    position: absolute;
    right: 12px;
    top: 98px;
    z-index: 5;
    gap: 14px;

    &__link {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        cursor: pointer;
        color: white;
        fill: white;
        //background-color: var(--body-light-muted-color);
        backdrop-filter: blur(5px);
        border: 1px solid var(--card-border-color);

        svg {
            width: 22px;
            height: 22px;
            margin-top: 5px;
            margin-left: 5px;
        }
    }
}
</style>
