<template>
    <div class="nft-list-container">
        <template v-if="items.length === 0">
            <div v-if="isLoading" class="nft-items-container">
                <div class="user-nft nft-preview--loading" v-for="item in skeletonCount" v-bind:key="`skeleton_${item}`">
                    <div class="nft-preview__image skeleton"></div>
                    <footer class="user-nft__footer">
                        <div class="user-nft__name">
                            <span class="skeleton">Some title</span>
                        </div>
                        <div class="nft-preview__meta">
                            <span class="skeleton skeleton--inline">Item 69 of 228</span>
                        </div>
                    </footer>
                </div>
            </div>

            <!--<div v-else class="nft-collection-error-message">-->
            <!--    <template v-if="itemCount === -1">-->
            <!--        <h2 class="nft-collection-error-message__title" v-text="$t('nft.collection.nonseq_collection_header')"/>-->
            <!--        <div class="nft-collection-error-message__message">-->
            <!--            <i18n path="nft.collection.nonseq_collection_text">-->
            <!--                <ui-link v-bind:to="{ name: 'nft', hash: '#transactions', params: { address } }">-->
            <!--                    {{$t('nft.collection.nonseq_collection_text_account_link')}}-->
            <!--                </ui-link>-->
            <!--            </i18n>-->
            <!--        </div>-->
            <!--    </template>-->

            <!--    <template v-else>-->
            <!--        <h2 class="nft-collection-error-message__title" v-text="$t('nft.collection.empty_collection_header')"/>-->
            <!--        <div class="nft-collection-error-message__message">-->
            <!--            <i18n path="nft.collection.empty_collection_text">-->
            <!--                <ui-link v-bind:to="{ name: 'nft', hash: '#transactions', params: { address } }">-->
            <!--                    {{$t('nft.collection.empty_collection_text_account_link')}}-->
            <!--                </ui-link>-->
            <!--            </i18n>-->
            <!--        </div>-->
            <!--    </template>-->
            <!--</div>-->
        </template>

        <div v-else class="nft-items-container">
            <nft-item-preview-card v-for="item in items"
                v-bind:key="item.item_address"
                v-bind:address="item.item_address"
                v-bind:index="item.index"
                v-bind:invalid="item.incomplete"
                v-bind:itemCount="itemCount"
                v-bind:name="item.metadata && item.metadata.name ? item.metadata.name : undefined"
                v-bind:images="item.metadata && item.metadata.image ? item.metadata.image : {}"
                v-bind:isReloading="item.isReloading"
                v-on:reload="handleReload"/>
        </div>

        <footer class="nft-items-container__loader">
            <ui-mugen-scroll
                v-bind:handler="loadMore"
                v-bind:shouldHandle="shouldHandleScroll"
                v-bind:showButton="showPreloader"
                v-bind:isLoading="isLoading"/>
        </footer>
    </div>
</template>

<script>
import NftItemPreviewCard from '~/components/nft/NftItemPreviewCard.vue';
import { getNftCollectionItems, getNftItemByCollectionIndex } from '~/api';
import { getCollectionItems } from '~/api/getgems';

const PER_PAGE = 12;

export default {
    props: {
        address: String,
        itemCount: Number,
    },
    data() {
        return {
            items: [],
            isLoading: true,
            cursor: undefined,
        };
    },

    watch: {
        address() {
            this.cursor = undefined;
            this.items = [];
        },
        itemCount: {
            immediate: true,
            handler(count) {
                // shows item skeletons until we load the collection info and have item count:
                return Number.isInteger(count) && this.loadItems();
            },
        },
    },

    computed: {
        hasMoreItems() {
            if (this.cursor) return true;
            return this.items.length < this.itemCount;
        },

        showPreloader() {
            return this.items.length > 0 && this.hasMoreItems;
        },

        shouldHandleScroll() {
            return !this.isLoading
                && this.hasMoreItems
                && this.items.length > 0;
        },
        skeletonCount() {
            return Math.max(Math.min(this.itemCount || PER_PAGE, PER_PAGE), 0);
        },
    },

    methods: {
        async loadItems(rawLimit, rawOffset) {
            const limit = rawLimit || Math.min(this.itemCount, PER_PAGE);
            const offset = rawOffset || 0;

            // Some collections have -1 as item count (like DNS collection)
            if (limit > 0 && !this.cursor) {
                this.isLoading = true;
                const response = await getNftCollectionItems(this.address, { limit, offset });

                this.items = this.items.concat(response.items.map(item => ({ ...item, isReloading: false })));
            } else {
                const getgemsItems = await getCollectionItems(this.address, 12, this.cursor);
                this.cursor = getgemsItems?.cursor;

                const getgemsItemsFormatted = [];

                if (getgemsItems?.items && getgemsItems?.items.length > 0) {
                    getgemsItems.items.forEach((item) => {
                        const img = item?.content?.thumb?.sized || item?.content?.preview || item?.content?.animated?.baseUrl;

                        const scheme = {
                            item_address: item?.item_address,
                            index: item?.index,
                            owner_address: item?.owner_address,
                            metadata: {
                                name: item?.name,
                                description: item?.description,
                                image: {
                                    original: img,
                                    i: img,
                                    w320: img,
                                    w640: img,
                                    w960: img,
                                },
                            },
                        };

                        getgemsItemsFormatted.push(scheme);
                    });
                }
                this.items = this.items.concat(getgemsItemsFormatted.map(item => ({ ...item, isReloading: false })));
            }

            this.isLoading = false;
        },

        loadMore() {
            const total = this.itemCount;
            const offset = this.items.length;

            let limit = PER_PAGE;

            if ((limit + offset) > total) {
                limit = total - offset;
            }

            this.loadItems(limit, offset);
        },

        handleReload(index) {
            const idx = this.items.findIndex(item => item.index === index);

            this.items[idx].isReloading = true;

            getNftItemByCollectionIndex(this.address, index + 1).then((item) => {
                this.items.splice(idx, 1, item);
            });
        },
    },
    components: { NftItemPreviewCard },
};
</script>

<style scoped lang="scss">
.nft-list-container {
    padding: 0 12px;
}
</style>
