<template>

    <tbody style="position: relative;">
        <tr class="new-row-animation event-overview-row">
            <td class="tx-mobile-flex-content">

                <ui-link class="tx-table-cell-icon tx-table__badge--burn" v-bind:to="transactionRoute" v-if="!type">
                    <icon-tx-burn />
                </ui-link>
                <ui-link class="tx-table-cell-icon tx-table__badge--sale" v-bind:to="transactionRoute" v-if="type === 'put-up-for-sale'">
                    <icon-tx-sale />
                </ui-link>
                <ui-link class="tx-table-cell-icon tx-table-cell-icon--out" v-bind:to="transactionRoute" v-if="type === 'transfer'">
                    <icon-tx-sent />
                </ui-link>
                <ui-link class="tx-table-cell-icon tx-table__badge--bid" v-bind:to="transactionRoute" v-if="type === 'put-up-for-auction'">
                    <icon-tx-bid />
                </ui-link>
                <ui-link class="tx-table-cell-icon tx-table-cell-icon--in" v-bind:to="transactionRoute" v-if="type === 'mint'">
                    <icon-tx-mint />
                </ui-link>
                <ui-link class="tx-table-cell-icon tx-table-cell-icon--out" v-bind:to="transactionRoute" v-if="type === 'cancel-sale'">
                    <icon-tx-cancel />
                </ui-link>
                <ui-link class="tx-table-cell-icon tx-table-cell-icon--out" v-bind:to="transactionRoute" v-if="type === 'cancel-auction'">
                    <icon-tx-cancel-bid />
                </ui-link>
                <ui-link class="tx-table-cell-icon tx-table__badge--sold" v-bind:to="transactionRoute" v-if="type === 'sold'">
                    <icon-tx-sold />
                </ui-link>

                <div class="tx-mobile-content">
                    <div class="tx-block-mobile-content__wrapper tx-block-mobile-content-swap-wrapper">
                        <div class="tx-block-mobile-content__row tx-block-mobile-swap-items">
                            <div class="tx-block-mobile-content__left">
                                <ui-timeago class="muted" v-bind:timestamp="time * 1000"/>
                            </div>
                            <div class="tx-block-mobile-content__right">
                                <template v-if="price && type !== 'cancel-sale'">
                                    <span>{{ $ton(price) }} TON</span>
                                </template>
                            </div>
                        </div>
                        <div class="tx-block-mobile-content__row">
                            <div class="tx-block-mobile-content__left">
                                <template v-if="oldOwner">
                                    <ui-address v-bind:address="oldOwner" hide-name/>
                                </template>
                                <template v-else-if="owner">
                                    <ui-address v-bind:address="owner" hide-name/>
                                </template>

                                <div class="tx-table-action-icon" v-if="newOwner">
                                    <arrow-right />
                                </div>

                                <template v-if="newOwner">
                                    <ui-address v-bind:address="newOwner" hide-name />
                                </template>

                                <span v-if="!oldOwner && !owner && !newOwner" class="muted">{{ $t('common.empty') }}</span>
                            </div>
                            <div class="tx-block-mobile-content__right">
                                <span class="muted" v-if="!type">{{ $t('nft.item.mobile_badge.burn') }}</span>
                                <span class="muted" v-if="type === 'mint'">{{ $t('nft.item.mobile_badge.mint') }}</span>
                                <span class="muted" v-if="type === 'cancel-sale'">{{ $t('nft.item.mobile_badge.cancel_sale') }}</span>
                                <span class="muted" v-if="type === 'cancel-auction'">{{ $t('nft.item.mobile_badge.cancel_bid') }}</span>
                                <span class="muted" v-if="type === 'put-up-for-auction'">{{ $t('nft.item.mobile_badge.bid') }}</span>
                                <span class="muted" v-if="type === 'put-up-for-sale'">{{ $t('nft.item.mobile_badge.sale') }}</span>
                                <span class="muted" v-if="type === 'transfer'">{{ $t('nft.item.mobile_badge.sent') }}</span>
                                <!-- {{ type }} -->
                            </div>
                        </div>

                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>
import IconTxBurn from '@img/icons/tonscan/badges/burn.svg?vue';
import IconTxSale from '@img/icons/tonscan/badges/sale.svg?vue';
import IconTxSent from '@img/icons/tonscan/badges/sent.svg?vue';
import IconTxBid from '@img/icons/tonscan/badges/bid.svg?vue';
import IconTxMint from '@img/icons/tonscan/badges/mint.svg?vue';
import IconTxCancel from '@img/icons/tonscan/badges/cancel.svg?vue';
import IconTxCancelBid from '@img/icons/tonscan/badges/cancel-bid.svg?vue';
import IconTxSold from '@img/icons/tonscan/badges/sold.svg?vue';
import ArrowRight from '@img/icons/tonscan/arrow-small-right.svg?vue';

export default {
    props: {
        hash: String,
        time: Number,
        owner: String,
        oldOwner: String,
        newOwner: String,
        price: String,
        type: String,
    },

    computed: {
        transactionRoute() {
            return {
                name: 'tx',
                params: {
                    hash: this.hash,
                },
            };
        },
    },

    components: {
        IconTxBurn,
        IconTxSale,
        IconTxSent,
        IconTxBid,
        IconTxMint,
        IconTxCancel,
        IconTxCancelBid,
        IconTxSold,
        ArrowRight,
    },
};
</script>

<style lang="scss">
.tx-mobile-block-row {
    margin-bottom: 4px;
}

.tx-mobile-block-row:last-child {
    margin-bottom: 0;
}

.tx-mobile-top-margin {
    margin-top: 11px;
}
</style>
