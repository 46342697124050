<template>
    <section>
        <div class="suspended-info">
            <h1 class="suspended-info__title" v-text="$t('suspended.header')"/>
            <p class="suspended-info__lead" v-html="$t('suspended.description')"/>
        </div>

        <div class="card">
            <div class="tx-history-wrap desktop-table">
                <table class="ui-table suspended-table">
                    <thead>
                        <tr>
                            <th width="24" class="ui-table__cell--align-right">#</th>
                            <th v-text="$t('address.info.address')"/>
                            <th v-text="$t('address.info.balance')"/>
                        </tr>
                    </thead>

                    <tbody v-if="earlyMiners === undefined">
                        <tr v-for="i in 20" v-bind:key="`suspended_miner_${i}`">
                            <td class="ui-table__cell--align-right"><span class="skeleton">100</span></td>
                            <td><span class="skeleton" style="width: 65%;">EQAAA...AAAA</span></td>
                            <td><span class="skeleton" style="width: 30%;">12345 TON</span></td>
                        </tr>
                    </tbody>

                    <tbody v-else>
                        <tr v-for="(miner, idx) in earlyMiners" v-bind:key="`suspended_miner_${miner.address}`">
                            <td class="ui-table__cell--align-right">
                                <div class="muted" v-text="idx + 1"/>
                            </td>
                            <td>
                                <ui-address hide-name v-bind:address="miner.address"/>
                            </td>
                            <td>
                                {{$ton(miner.balance, 0)}} TON
                            </td>
                        </tr>

                        <tr class="suspended-table__total">
                            <td/>
                            <td>
                                {{$t('common.total')}}
                            </td>
                            <td>
                                {{$ton(totalSuspended, 0)}} TON
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</template>

<script>
import { getSuspendedAddresses } from '~/api/tontech.js';

export default {
    data() {
        return {
            earlyMiners: undefined,
            totalSuspended: 0,
        };
    },

    mounted() {
        getSuspendedAddresses().then((miners) => {
            this.earlyMiners = miners.filter(miner => miner.address !== '');
            this.totalSuspended = miners.reduce((sum, miner) => sum + Number(miner.balance || 0), 0);
        });
    },
};
</script>

<style lang="scss">
.suspended-table {
    border-radius: 12px;
    overflow: hidden;
    &__total {
        background-color: var(--tx-table-thead-alt-background);
        font-weight: 500;
    }
}

.suspended-info {
    padding: 0 4px;
    &__title {
        font-size: 28px;
        margin-bottom: 10px;
    }
    &__lead {
        margin-top: 0;
        margin-bottom: 22px;
        font-size: 16px;
        line-height: 1.5;
    }
}
</style>
