<template>
    <section>
        <section class="nft-collection-card">
            <!-- Left part of top blocks -->
            <div class="card nft-collection-cover-container">
                <social-links v-if="socialLinks.length > 0" v-bind:socialLinks="socialLinks" />
                <div class="nft-collection-cover-container__header">
                    <img v-show="coverImageSrc && coverImageLoaded"
                        v-bind:src="coverImageSrc"
                        v-on:error="coverLoadError = true"
                        v-on:load="coverImageLoaded = true"
                        v-bind:style="coverImageSrc !== coverImage ? 'filter: blur(6px)' : ''"
                    />
                    <div v-if="!coverImageLoaded" class="nft-collection-cover-container__header--skeleton"></div>
                </div>
                <div class="nft-collection-cover-container__image">
                    <img v-if="image.original" class="nft-collection-cover"
                        v-bind:src="image.w320"
                        v-bind:style="{ backgroundImage: `url(${image.i})` }"
                        v-bind:srcset="`${image.w120} 120w, ${image.w320} 320w, ${image.w640} 640w, ${image.w960} 960w`"
                        sizes="(max-width: 600px) 240px, 120px"
                    >

                    <div v-else class="nft-collection-cover skeleton--inline"/>
                </div>
                <div class="nft-collection-cover-container__content">
                    <div class="nft-collection-cover-container__content--text">
                        <h2 class="nft-collection-header__title">
                            <span v-if="name === undefined" class="skeleton skeleton--inline">Some cool NFTs 2599</span>
                            <template v-else-if="!name">No name</template>
                            <template v-else>{{name}}</template>
                        </h2>
                        <div class="nft-collection-header__description">
                            <span class="muted" v-if="description === null || description === ''" v-text="$t('common.no_description')"/>
                            <span v-else-if="!description" class="skeleton skeleton--inline">Some cool NFTs pretty long description text</span>
                            <span v-else v-bind:class="{ 'nft-collection-header__description--truncated': isTruncated && !descriptionOpened }"
                                v-bind:style="{ 'cursor': isTruncated && !descriptionOpened ? 'pointer' : 'text' }"
                                v-on:click="descriptionOpened = true"
                            >
                                {{description}}
                            </span>
                        </div>
                    </div>
                    <div class="nft-meta-items">
                        <div class="nft-meta-item">
                            <div class="nft-meta-item__title">
                                <span v-if="holders >= 0">{{$round(holders)}}</span>
                                <span v-else class="skeleton skeleton--inline">100000</span>
                            </div>
                            <div class="nft-meta-item__description">
                                <span v-if="holders >= 0">{{$tc('nft.item.holders', Math.max(0, holders))}}</span>
                                <span v-else class="skeleton skeleton--inline">NNNN</span>
                            </div>
                        </div>
                        <div class="nft-meta-item" v-if="royalty !== null">
                            <div class="nft-meta-item__title">
                                <span v-if="royalty">{{royalty}} %</span>
                                <span v-else-if="royalty === undefined" class="skeleton skeleton--inline">100000</span>
                            </div>
                            <div class="nft-meta-item__description">
                                <span v-if="royalty">{{ $t('nft.item.royalty') }}</span>
                                <span v-else-if="royalty === undefined" class="skeleton skeleton--inline">NNNN</span>
                            </div>
                        </div>
                        <div class="nft-meta-item">
                            <div class="nft-meta-item__title">
                                <span v-if="volumeFormatted">
                                    <span class="icon-currency">{{currencySymbol}}</span>{{ volumeFormatted }}
                                </span>
                                <span v-else-if="!volumeFormatted" class="skeleton skeleton--inline">100000</span>
                            </div>
                            <div class="nft-meta-item__description">
                                <span v-if="volumeFormatted">{{ $t('nft.item.total_volume') }}</span>
                                <span v-else class="skeleton skeleton--inline">NNNN</span>
                            </div>
                        </div>
                        <div class="nft-meta-item">
                            <div class="nft-meta-item__title">
                                <span v-if="floorFormatted">
                                    <span class="icon-currency">{{currencySymbol}}</span>{{floorFormatted}}
                                </span>
                                <span v-else class="skeleton skeleton--inline">100000</span>
                            </div>
                            <div class="nft-meta-item__description">
                                <span v-if="floorFormatted">{{ $t('nft.item.floor') }}</span>
                                <span v-else-if="!floorFormatted" class="skeleton skeleton--inline">NNNN</span>
                                <span v-else>{{ $t('nft.item.floor') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Right part of top blocks -->
            <div class="nft-collection-info">
                <div class="card" style="padding: 2px 0">
                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('nft.item.address')"/>
                        <span v-if="address === undefined" class="skeleton">Some other Address</span>
                        <span v-else-if="address === null">{{ $t('nft.item.no_owner') }}</span>
                        <div v-else class="card-row__value">
                            <ui-copy-button class="card-main-address"
                                v-bind:successMessage="$t('address.info.copy_success')"
                                v-bind:copy="address"
                            >
                                {{ address }}
                            </ui-copy-button>

                            <span class="card-main-qr-button" v-on:click="qrModalVisible = true">
                                <icon-qr class="card-main-qr-button__icon"/>
                            </span>
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('nft.item.owner')"/>
                        <div class="card-row__value">
                            <span v-if="ownerAddress === undefined" class="skeleton">Some other Address</span>
                            <span v-else-if="ownerAddress === null" class="muted">{{ $t('nft.item.no_owner') }}</span>
                            <ui-address v-else v-bind:address="ownerAddress"/>
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('nft.item.balance')"/>
                        <div v-if="wallet" class="card-row__value">
                            <span>{{$ton(wallet.balance)}}</span>
                            <span>TON</span>
                            <template v-if="wallet.balance != '0'">
                                <span v-if="$store.state.exchangeRate" style="color: #717579">
                                    ≈ <ui-fiat v-bind:tonValue="wallet.balance"/>
                                </span>
                            </template>
                        </div>
                        <div v-else class="card-row__value">
                            <span class="skeleton">00000 TON ≈ 00000 USD</span>
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('nft.item.metadata_source')"/>
                        <div class="card-row__value">
                            <ui-metadata-source v-bind:url="metadataUrl"/>
                        </div>
                    </div>

                    <div class="card-row">
                        <div class="card-row__name" v-text="$t('address.info.details')"/>
                        <div class="card-row__value">
                            <div class="card-row__value-tags">
                                <div class="card-row__value-tag-item card-row__value-tag card-row__value-tag-green">
                                    <span>{{ $t('address.info.type_active') }}</span>
                                </div>
                                <div class="card-row__value-tag-item card-row__value-tag card-row__value-tag-gray">
                                    {{ $t('nft.collection.contract_type') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <nft-collection-tabs
            v-bind:address="address"
            v-bind:isActive="true"
            v-bind:itemCount="itemCount"
        >
            <template v-slot:customFilter>
                <span class="item-count-number muted" v-if="showItemCount && $store.state.nftItemCountShow">{{ $tc('nft.item.count', $round(itemCount, 0)) }}</span>
                <custom-filter v-if="$store.state.filterShow" />
            </template>
        </nft-collection-tabs>

        <ui-modal class="qr-modal" v-bind:isOpen.sync="qrModalVisible">
            <ui-qr show-logo v-bind:value="`ton://transfer/${addressCanonical}`" v-bind:size="300"/>
        </ui-modal>
    </section>
</template>

<script>
import { prefixNumber } from '~/lib/Chart.js/helpers.js';
import { detectNft, getAddressInfo, getNftCollectionInfo } from '~/api';
import IconQr from '@img/icons/tonscan/qr-14.svg?inline';
import UiQr from '~/components/UiQr.vue';
import UiMetadataSource from '~/components/UiMetadataSource.vue';
import NftCollectionTabs from '~/components/nft/NftCollectionTabs.vue';
import CustomFilter from '~/components/address/Filter/Filter.vue';
import SocialLinks from '~/components/nft/SocialLinksList.vue';
import { showToast } from '~/toast';
import { getNftCollection } from '~/api/getgems';

export default {
    props: {
        address: String,
    },

    data() {
        return {
            itemCount: undefined,
            ownerAddress: undefined,
            name: undefined,
            description: undefined,
            image: {
                type: Object,
                default: () => ({}),
            },
            coverImage: undefined,
            externalLink: undefined,
            royalty: undefined,
            socialLinks: {
                type: Array,
                default: () => ([]),
            },
            floor: undefined,
            holders: undefined,
            totalVolume: undefined,
            wallet: undefined,
            qrModalVisible: false,
            addressCanonical: undefined,
            metadataUrl: undefined,
            descriptionOpened: false,
            coverLoadError: false,
            coverImageLoaded: false,
            blurCover: false,
        };
    },

    watch: {
        address(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.floor = undefined;
                this.holders = undefined;
                this.totalVolume = undefined;
                this.coverLoadError = false;
                this.coverImageLoaded = false;
                this.coverImage = undefined;
                this.image = {};
                this.wallet = undefined;
                this.ownerAddress = undefined;
                this.metadataUrl = undefined;
                this.descriptionOpened = false;
                this.coverImageLoaded = false;
                this.checkType();
            }
        },
    },

    computed: {
        isTruncated() {
            return !(this.isMobile || this.isTablet) && this.description.length > 30;
        },
        showItemCount() {
            return !this.isMobile && this.itemCount > 0;
        },
        coverImageSrc() {
            if (this.coverLoadError && this.coverImage) {
                return this.image?.w640;
            } if (!this.coverImage) {
                return this.image?.w640;
            }
            return this.coverImage;
        },
        floorFormatted() {
            const fiatFloor = this.floor * this.$store.state.exchangeRate;
            return fiatFloor < 1 ? prefixNumber(fiatFloor) : prefixNumber(Math.floor(fiatFloor));
        },
        volumeFormatted() {
            const fiatVolume = this.totalVolume * this.$store.state.exchangeRate;

            if (fiatVolume < 1000) return this.$fiat(fiatVolume);
            return prefixNumber(fiatVolume);
        },
        currencySymbol() {
            const symbols = { usd: '$', rub: '₽', aed: 'د.إ' };
            const currentCurrency = this.$store.state.exchangeRateCurrency;
            return symbols[currentCurrency.toLowerCase()] || '';
        },
    },

    created() {
        this.checkType();
    },

    methods: {
        convertApiResponseToNftCollectionProps(response) {
            this.itemCount = response?.item_count;
            this.ownerAddress = response?.owner_address;
            this.name = response?.metadata?.name;
            this.description = response?.metadata?.description;
            this.image = response?.metadata?.image;
            this.coverImage = response?.metadata?.cover_image;
            this.externalLink = response?.metadata?.external_link;
            this.royalty = response?.royalty?.share_percent || null;
            this.socialLinks = response?.metadata?.social_links;
        },
        async checkType() {
            const data = await detectNft(this.address).catch(() => undefined);

            switch (data?.type) {
                case 'nft_item': {
                    this.$router.replace(this.$localizeRoute({
                        name: 'nft',
                        params: { address: this.address },
                    }));
                    break;
                }

                case 'nft_collection': {
                    this.convertApiResponseToNftCollectionProps(data.nft_collection);
                    await this.loadNftCollectionMeta();
                    await this.loadCollectionStats();
                    await this.loadAddressInfo();
                    break;
                }

                default: {
                    showToast(this.$t('nft.error.redirect_invalid'));

                    this.$router.replace(this.$localizeRoute({
                        name: 'address',
                        params: { address: this.address },
                    }));
                }
            }
        },
        async loadCollectionStats() {
            const response = await getNftCollection(this.address);
            this.floor = response.floorPrice;
            this.holders = response.holders;
            this.totalVolume = response.totalVolumeSold / 10 ** 9;
        },

        async loadNftCollectionMeta() {
            const response = await getNftCollectionInfo(this.address);
            this.metadataUrl = response?.nft_collection?.content_url;
        },

        async loadAddressInfo() {
            this.wallet = await getAddressInfo(this.address);
        },
    },

    components: {
        CustomFilter,
        NftCollectionTabs,
        IconQr, UiQr, UiMetadataSource, SocialLinks,
    },
};
</script>

<style lang="scss">
.icon-currency {
    display: inline-block;
    fill: currentColor;
    margin: 0 6px 0 0;
    color: var(--body-muted-text-color);
}
.nft-meta-wrapper {
    margin: 0 -8px;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-left: 8px;
    padding-right: 8px;
    scroll-snap-type: x mandatory;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    -ms-overflow-style: none;  /* Для Internet Explorer и Edge */
    scrollbar-width: none;  /* Для Firefox */
    &::-webkit-scrollbar {
        display: none;  /* Для Chrome, Safari и Opera */
    }
}
.nft-description-expander {
    background: linear-gradient(to left,var(--card-background) 35%,transparent);
    color: var(--blue-bright);
    cursor: pointer;
    margin-bottom: -10px;
    margin-top: -10px;
    padding: 2px 0;
    position: absolute;
    right: 0;
    width: 100%;
    text-align: right;
    bottom: 8px;
    z-index: 900;
    span {
        padding: 0 20px;
    }
}
.nft-collection-cover-container {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    background-color: var(--card-background);
    border: 1px solid var(--card-border-color);
    border-radius: 12px;
    overflow: hidden;
    width: 100%;
    max-width: 480px;
    align-self: flex-start;
    position: relative;
    &__header {
        position: relative;
        width: 100%;
        height: 88px;
        overflow: hidden;
        transform: scale(1.2);

        &--skeleton {
            display: block;
            background-color: var(--body-light-muted-color);
            width: 100%;
            height: 88px;
            position: absolute;
            top: 0;
            left: 0;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            margin: -1px;
            padding: 1px;
        }
    }
    &__content {
        width: 100%;
        padding: 12px 20px 11.4px;
        flex-grow: 1;
        box-sizing: border-box;

        &--text {
            padding-left: 115px;
            margin-bottom: 18px;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        max-width: 98px;
        max-height: 98px;
        border-radius: 16px;
        overflow: hidden;
        display: block;
        object-fit: contain;
        margin: auto;
        position: absolute;
        left: 20px;
        top: 50px;
        background: var(--address-tag-gray-background);
        border: 4px solid var(--card-background);
        img {
            height: 100%;
        }
        &.skeleton {
            border-radius: 8px;
            background-image: none;
            box-shadow: none;
            aspect-ratio: 1 / 1;
        }
    }
}
@media screen and (max-width: 991px) {
    .nft-collection-cover-container {
        &__content {
            padding-bottom: 16px;
        }
    }
}
@media screen and (max-width: 600px) {
    .nft-collection-cover-container {
        &__content {
            padding: 65px 12px 12px;
            &--text {
                padding-left: 0;
            }
        }
        &__image {
            max-width: 80px;
            max-height: 80px;
            top: 55px;
            left: 12px;
        }
    }
}
.nft-collection-header {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    min-width: 150px;
    &__title {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 5px;
        margin-top: 4px;
    }
    &__description {
        font-size: 14px;
        line-height: 1.4;
        color: var(--body-muted-text-color);
        position: relative;
        &--truncated {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }
    }
}
@media screen and (max-width: 600px) {
    .nft-collection-header {
        &__title {
            font-size: 24px;
        }
    }
}
.nft-collection-error-message {
    display: block;
    text-align: center;
    padding: 16px;
    max-width: 480px;
    margin: 100px auto;
    &__title {
        font-size: 36px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -1px;
        margin: 0;
        padding: 0;
    }
    &__message {
        color: #909097;
        margin-top: 10px;
        font-size: 16px;
        line-height: 1.5;
    }
}
.nft-meta-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
    width: calc(100% - 8px);
    margin: 0 0 0 4px;
    border: 1px solid var(--card-border-color);
    border-radius: 12px;
}
@media screen and (max-width: 600px) {
    .nft-meta-items {
        margin-top: 16px;
        margin-left: 0;
        width: 100%;
    }
}
.nft-meta-item {
    width: auto;
    flex: 1 1 auto;
    flex-basis: 0;
    border-right: 1px solid var(--card-border-color);
    padding: 8px 0;

    &__title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
    }
    &__description {
        font-size: 12px;
        color: var(--body-muted-text-color);
    }

    &:last-child {
        border-right: none;
    }
}
@media screen and (max-width: 600px) {
    .nft-meta-item {
        flex: auto;
    }
}
.nft-collection-info {
    .card + .card {
        margin-top: 15px;
    }
}
.nft-link {
    margin-bottom: 0!important;
}
.item-count-number {
    user-select: none;
    padding-right: 12px;
    white-space: nowrap;
    font-weight: 500;
}
</style>
