<script>
import IconNft from '@img/icons/material-duotone/view-in-ar.svg?inline';
import IconContract from '@img/icons/material-duotone/data-object.svg?inline';
import UiTabs from '~/components/UiTabs.vue';
import TabContractSources from '~/components/address/Verifier/Verifier.vue';
import NftList from '~/components/nft/Tabs/NftList.vue';
import TxHistory from '~/components/address/TxHistory.vue';
import IconList from '@img/icons/material-duotone/list.svg?inline';

export default {
    props: {
        isActive: Boolean,
        address: {
            type: String,
            required: true,
        },
        itemCount: Number,
    },

    render(createComponent) {
        return createComponent(UiTabs, {
            class: 'card card--tabbed',
            props: {
                tabs: this.tabs,
                changeUrlHash: true,
            },
            on: this.$listeners, // passthru listeners from parent
            scopedSlots: this.$scopedSlots,
        });
    },

    computed: {
        tabs() {
            const props = { address: this.address, itemCount: this.itemCount };
            const key = this.address; // keepalive key

            return [{
                key: 'items',
                text: this.$t('nft.item.nfts'),
                icon: IconNft,
                content: {
                    key, props,
                    eager: true,
                    component: NftList,
                },
            }, {
                key: 'transactions',
                text: this.$t('nft.item.recent_tx'),
                icon: IconList,
                content: {
                    key, props,
                    eager: true,
                    component: TxHistory,
                },
            }, {
                key: 'source',
                text: this.$t('address.tab_contract'),
                icon: IconContract,
                content: {
                    key,
                    component: TabContractSources,
                    props: {
                        address: this.address,
                        isActive: true,
                    },
                },
            }];
        },
    },
};
</script>
