<template>
    <div class="tx-overview-action-type">
        <div class="tx-overview-action-type__icon">
            <component v-bind:is="getIcon" />
        </div>
        {{ $t(`transactionPage.${this.event}`) }}
    </div>
</template>

<script>
import IconOut from '@img/icons/tonscan/transactions/tx-out.svg?vue';
import IconSwap from '@img/icons/tonscan/transactions/tx-swap.svg?vue';
import IconBurn from '@img/icons/tonscan/transactions/tx-burn.svg?vue';
import IconSmartContractExec from '@img/icons/tonscan/transactions/tx-contract.svg?vue';
import IconAuctionBid from '@img/icons/tonscan/transactions/tx-bid.svg?vue';
import IconChain from '@img/icons/tonscan/chain-16.svg?vue';
import IconJettonMint from '@img/icons/tonscan/transactions/tx-jetton-mint.svg?vue';

/* eslint-disable "quote-props" */
const iconMap = Object.freeze({
    TonTransfer: IconOut,
    NftItemTransfer: IconOut,
    JettonTransfer: IconOut,
    ContractDeploy: IconSmartContractExec,
    SmartContractExec: IconSmartContractExec,
    JettonSwap: IconSwap,
    JettonBurn: IconBurn,
    AuctionBid: IconAuctionBid,
    JettonMint: IconJettonMint,
});
export default {
    props: {
        event: String,
        name: String,
    },

    computed: {
        getIcon() {
            return iconMap[this.event] || IconChain;
        },
    },
};
</script>

<style lang="scss">
.tx-overview-action-type{
    display: flex;
    align-items: center;
    margin-right: 12px;

    &__icon {
        width: 17px;
        height: 17px;
        display: inline-block;
        margin-right: 6px;
    }
}
</style>
