<template>
    <line-chart
        hide-legend
        v-bind:labels="labels"
        v-bind:datasets="datasets"
        v-bind:chartType="'Baseline'"
        v-bind:interval="interval"
        jetton
    />
</template>

<script>
import LineChart from '~/lib/Lightchart/UiChartLine.vue';

export default {
    props: {
        chartData: {
            type: Array,
            required: true,
            default: () => [],
        },
        interval: Number,
    },

    data() {
        return {
            datasets: [],
            labels: [],
        };
    },

    watch: {
        chartData: {
            immediate: true,
            handler: 'loadData',
        },
    },

    computed: {
        currentCurrency() {
            return this.$store.state.exchangeRateCurrency.toLowerCase();
        },
        currencySymbol() {
            const symbols = {
                usd: 'USD',
                rub: 'RUB',
                aed: 'AED',
            };

            return symbols[this.currentCurrency] || '';
        },
    },

    methods: {
        loadData() {
            if (!Array.isArray(this.chartData) || !this.chartData.length) {
                console.warn('Invalid or empty chartData'); // eslint-disable-line no-console
                this.labels = [];
                this.datasets = [];
                return;
            }

            this.labels = this.chartData.map(([timestamp]) => timestamp * 1000);
            const priceDataset = {
                data: this.chartData.map(([, value]) => value),
                label: this.$t('jetton.price'),
                suffix: this.currencySymbol,
            };

            this.datasets = [priceDataset];
        },
    },

    components: {
        LineChart,
    },
};
</script>

<style lang="scss">
.jetton-chart-container {
    width: 100%;
    height: 100%;
    display: flex;
}

.interval-selector__item {
    text-align: center;
}

@media screen and (max-width: 991px) {
    .interval-selector-jetton {
        width: 100%;
        justify-content: space-around;

        .interval-selector__item {
            width: 100%;
            padding: 4px 0;
            text-align: center;
        }
    }
    .jetton-chart-container {
        padding: 0 0 0 10px;
        width: calc(100% - 10px);
        aspect-ratio: 16 / 12;
    }
}
</style>
