<template>
    <div class="widget-channel-item">
        <a v-bind:href="channelLink" class="widget-channel-link" target="_blank">
            <div class="widget-channel">
                <div class="widget-channel-header">
                    <div class="widget-channel-logo">
                        <img v-bind:src="channelLogo" />
                    </div>
                    <div class="widget-channel-name">
                        {{ channelName }}
                    </div>
                    <div class="widget-channel-date">
                        <ui-timeago v-bind:timestamp="date" />
                    </div>
                </div>
                <div class="widget-channel-content" v-html="text" />
            </div>
        </a>
        <div class="widget-channel-separator"/>
    </div>
</template>

<script>
export default {
    props: {
        id: Number,
        channelId: Number,
        channelName: String,
        channelUsername: String,
        channelAvatarVersion: Number,
        text: String,
        date: Number,
    },

    computed: {
        channelLink() {
            return `https://t.me/${this.channelUsername}/${this.id}`;
        },
        channelLogo() {
            return `https://cdn.tlgrm.eu/channels/${this.channelId}/avatar64.webp?v=${this.channelAvatarVersion}`;
        },
    },
};
</script>

<style lang="scss">
.widget-channel-item {
    .widget-channel-link,
    .widget-channel-link:hover {
        color: inherit;
        text-decoration: none;
    }
}

.widget-channel-item:last-child
.widget-channel-separator {
    display: none;
}

.widget-channel {
    padding: 14px;
    border-radius: 10px;
    margin: 10px;
    cursor: pointer;
    transition: all 0.2s;
}

.widget-channel:hover {
    background-color: var(--body-light-muted-color);
}

.widget-channel-header {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 14px;
}
.widget-channel-name {
    flex-grow: 1;
    font-weight: 500;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.widget-channel-date {
    white-space: pre;
    text-align: right;
    color: var(--body-muted-text-color);
}

.widget-channel-logo {
    img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-top: 2px;
    }
}

.widget-channel-content {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    height: auto;
    max-height: 100px;
    line-height: 20px;

    .tg-emoji {
        margin-bottom: -2px;
        margin-right: 2px;
        width: 16px;
        height: 16px;
    }

    blockquote {
        display: block;
        padding: 0px 10px;
        margin: 10px 4px;
        border-left: 2px solid currentColor;
        font-size: 0.94em;
    }

    b, strong {
        font-weight: 500;
    }
}

.widget-channel-separator {
    margin: 0 24px;
    height: 1px;
    background-color: var(--card-row-separator);
}

@media screen and (max-width: 600px) {
    .widget-channel {
        margin: 10px 0;
    }

    .widget-channel-separator {
        margin: 0 10px;
    }

    .widget-channel-logo {
        img {
            width: 20px;
            height: 20px;
        }
    }

    .widget-channel-date {
        font-size: 14px;
    }

    .widget-channel-content {
        font-size: 14px;
    }
}
</style>
