var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.type === 'NftItemTransfer')?_c('div',{staticClass:"tx-row-msg-action-badge"},[_c('ui-link',{staticClass:"ui-inline-nft ui-inline-nft-mobile-swap",attrs:{"to":{ name: 'nft', params: { address: _vm.address }}}},[_c('div',{staticClass:"ui-inline-nft__left"},[_c('div',{staticClass:"ui-inline-nft-image"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasError),expression:"!hasError"}],staticClass:"ui-inline-nft-image__img",attrs:{"src":_vm.imageSrc,"alt":""},on:{"error":function($event){_vm.hasError = true}}}),_vm._v(" "),_c('icon-nft',{staticClass:"ui-inline-nft-image__svg"})],1)]),_vm._v(" "),_c('div',{staticClass:"ui-inline-nft__title"},[_vm._v("Deploy NFT")])])],1):_vm._e(),_vm._v(" "),(_vm.type === 'TonTransfer')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('span',[_vm._v(_vm._s(_vm.$ton(_vm.actionDetails?.amount))+" TON")])]):_vm._e(),_vm._v(" "),(_vm.type === 'JettonBurn')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('span',{staticClass:"ui-inline-jetton__value"},[_vm._v(_vm._s(_vm.formatSwap(_vm.actionDetails.amount, _vm.actionDetails.jetton.decimals)))]),_vm._v(" "),_c('ui-link',{attrs:{"to":{ name: 'jetton', params: {
                address: _vm.canonizeAddress(_vm.actionDetails.jetton.address),
            }
            }}},[_vm._v("\n            "+_vm._s(_vm.actionDetails.jetton.symbol)+"\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.type === 'JettonTransfer')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('span',{staticClass:"ui-inline-jetton__value"},[_vm._v(_vm._s(_vm.formatSwap(_vm.actionDetails.amount, _vm.actionDetails.jetton.decimals)))]),_vm._v(" "),_c('ui-link',{attrs:{"to":{ name: 'jetton', params: {
                address: _vm.canonizeAddress(_vm.actionDetails.jetton.address),
            }
            }}},[_vm._v("\n            "+_vm._s(_vm.actionDetails.jetton.symbol)+"\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.type === 'SmartContractExec')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('span',[_vm._v(_vm._s(_vm.$ton(_vm.actionDetails?.ton_attached))+" TON")])]):_vm._e(),_vm._v(" "),(_vm.type === 'JettonSwap')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[(!_vm.actionDetails.jetton_master_in)?[_c('span',[_vm._v(_vm._s(_vm.$ton(_vm.actionDetails?.ton_in))+" TON")])]:[_c('span',{staticClass:"ui-inline-jetton__value"},[_vm._v(_vm._s(_vm.formatSwap(_vm.actionDetails.amount_in, _vm.actionDetails.jetton_master_in.decimals)))]),_vm._v(" "),_c('ui-link',{attrs:{"to":{ name: 'jetton', params: {
                    address: _vm.canonizeAddress(_vm.actionDetails.jetton_master_in.address),
                }
                }}},[_vm._v("\n                "+_vm._s(_vm.actionDetails.jetton_master_in.symbol)+"\n            ")])],_vm._v(" "),_c('div',{staticClass:"tx-table-action-icon"},[_c('arrow-right')],1),_vm._v(" "),(!_vm.actionDetails.jetton_master_out)?[_c('span',[_vm._v(_vm._s(_vm.$ton(_vm.actionDetails?.ton_out))+" TON")])]:[_c('span',{staticClass:"ui-inline-jetton__value"},[_vm._v(_vm._s(_vm.formatSwap(_vm.actionDetails.amount_out, _vm.actionDetails.jetton_master_out.decimals)))]),_vm._v(" "),_c('ui-link',{attrs:{"to":{ name: 'jetton', params: {
                    address: _vm.canonizeAddress(_vm.actionDetails.jetton_master_out.address),
                }}}},[_vm._v("\n                "+_vm._s(_vm.actionDetails.jetton_master_out.symbol)+"\n            ")])]],2):_vm._e(),_vm._v(" "),(_vm.type === 'JettonMint')?_c('div',{staticClass:"tx-row-tx-event-action-badge"},[_c('span',{staticClass:"ui-inline-jetton__value"},[_vm._v(_vm._s(_vm.$ton(_vm.actionDetails.amount, _vm.actionDetails.jetton?.decimals ?? 9)))]),_vm._v(" "),(_vm.actionDetails.jetton.address)?_c('ui-link',{attrs:{"to":{ name: 'jetton', params: {
                address: _vm.canonizeAddress(_vm.actionDetails.jetton.address),
            }}}},[_vm._v("\n            "+_vm._s(_vm.actionDetails?.jetton?.symbol || _vm.actionDetails?.symbol)+"\n        ")]):[_vm._v("\n            "+_vm._s(_vm.actionDetails?.jetton?.symbol || _vm.actionDetails?.symbol)+"\n        ")]],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }