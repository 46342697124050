<template>
    <section>
        <div class="tx-row-msg-action-badge" v-if="type === 'NftItemTransfer'">
            <ui-link v-bind:to="{ name: 'nft', params: { address }}" class="ui-inline-nft ui-inline-nft-mobile-swap">
                <div class="ui-inline-nft__left">
                    <div class="ui-inline-nft-image">
                        <img v-bind:src="imageSrc" v-show="!hasError" v-on:error="hasError = true" alt="" class="ui-inline-nft-image__img">
                        <icon-nft class="ui-inline-nft-image__svg"/>
                    </div>
                </div>
                <div class="ui-inline-nft__title">Deploy NFT</div>
            </ui-link>
        </div>
        <div class="tx-row-tx-event-action-badge" v-if="type === 'TonTransfer'">
            <span>{{$ton(actionDetails?.amount)}} TON</span>
        </div>
        <div class="tx-row-tx-event-action-badge" v-if="type === 'JettonBurn'">
            <span class="ui-inline-jetton__value">{{ formatSwap(actionDetails.amount, actionDetails.jetton.decimals) }}</span>
            <ui-link
                v-bind:to="{ name: 'jetton', params: {
                    address: canonizeAddress(actionDetails.jetton.address),
                }
                }">
                {{ actionDetails.jetton.symbol }}
            </ui-link>
        </div>
        <div class="tx-row-tx-event-action-badge" v-if="type === 'JettonTransfer'">
            <span class="ui-inline-jetton__value">{{ formatSwap(actionDetails.amount, actionDetails.jetton.decimals) }}</span>
            <ui-link
                v-bind:to="{ name: 'jetton', params: {
                    address: canonizeAddress(actionDetails.jetton.address),
                }
                }">
                {{ actionDetails.jetton.symbol }}
            </ui-link>
        </div>
        <div class="tx-row-tx-event-action-badge" v-if="type === 'SmartContractExec'">
            <span>{{$ton(actionDetails?.ton_attached)}} TON</span>
        </div>
        <div class="tx-row-tx-event-action-badge" v-if="type === 'JettonSwap'">
            <template v-if="!actionDetails.jetton_master_in">
                <span>{{$ton(actionDetails?.ton_in)}} TON</span>
            </template>
            <template v-else>
                <span class="ui-inline-jetton__value">{{ formatSwap(actionDetails.amount_in, actionDetails.jetton_master_in.decimals) }}</span>
                <ui-link
                    v-bind:to="{ name: 'jetton', params: {
                        address: canonizeAddress(actionDetails.jetton_master_in.address),
                    }
                    }">
                    {{ actionDetails.jetton_master_in.symbol }}
                </ui-link>
            </template>
            <div class="tx-table-action-icon">
                <arrow-right />
            </div>
            <template v-if="!actionDetails.jetton_master_out">
                <span>{{$ton(actionDetails?.ton_out)}} TON</span>
            </template>
            <template v-else>
                <span class="ui-inline-jetton__value">{{ formatSwap(actionDetails.amount_out, actionDetails.jetton_master_out.decimals) }}</span>
                <ui-link
                    v-bind:to="{ name: 'jetton', params: {
                        address: canonizeAddress(actionDetails.jetton_master_out.address),
                    }}">
                    {{ actionDetails.jetton_master_out.symbol }}
                </ui-link>
            </template>
        </div>
        <div class="tx-row-tx-event-action-badge" v-if="type === 'JettonMint'">
            <span class="ui-inline-jetton__value">{{$ton(actionDetails.amount, actionDetails.jetton?.decimals ?? 9)}}</span>
            <ui-link v-if="actionDetails.jetton.address"
                v-bind:to="{ name: 'jetton', params: {
                    address: canonizeAddress(actionDetails.jetton.address),
                }}">
                {{actionDetails?.jetton?.symbol || actionDetails?.symbol}}
            </ui-link>
            <template v-else>
                {{actionDetails?.jetton?.symbol || actionDetails?.symbol}}
            </template>
        </div>
    </section>
</template>

<script>
import IconNft from '@img/icons/tonscan/nft-14.svg?inline';
import ArrowRight from '@img/icons/tonscan/arrow-small-right.svg?vue';
import { canonizeAddress } from '~/tonweb.js';
import { getNftItemPreviewUrl } from '~/api/extenderContracts.js';
import { formatSwap } from '~/helpers.js';

export default {
    props: {
        type: String,
        actionDetails: Object,
    },

    data() {
        return {
            address: null,
            imageSrc: null,
            hasError: false,
        };
    },

    methods: {
        canonizeAddress,
        formatSwap,
        setFriendlyAddress() {
            if (this.actionDetails && this.actionDetails.nft) {
                const canonizedAddress = canonizeAddress(this.actionDetails.nft, {
                    type: undefined,
                });

                if (canonizedAddress) {
                    this.address = canonizedAddress;
                }
            }
        },

        updateImageSrc() {
            if (this.address) {
                this.imageSrc = getNftItemPreviewUrl(this.address);
            }
        },
    },

    watch: {
        address(newAddress) {
            if (newAddress) {
                this.updateImageSrc();
            }
        },
    },

    created() {
        this.setFriendlyAddress();
    },

    components: {
        IconNft,
        ArrowRight,
    },
};
</script>

<style lang="scss">
.tx-row-tx-event-action-badge {
    display: flex;
    align-items: center;
}

@media (max-width: 599px) {
    .ui-inline-nft-mobile-swap {
        transform: none!important;
        max-height: 30px;
    }
}
</style>
